@import "../../../../assets/scss/common";

.DealName {
  font-family: Georgia, "Times New Roman", Times, serif;
  color: $secondary;
}

.Customer {
  p {
    margin: 0;
  }
}

.BasicDetails {
  margin: 1rem 0;
  white-space: pre-line;
  th {
    text-align: left;
    word-break: normal;
    vertical-align: top;
  }
}

@page {
  size: A4;
  margin: 2rem 1rem;
}

.Print {
  font-size: 1.75rem;
  div,
  p {
    break-inside: avoid;
  }
}

.Footer {
  p {
    margin: 0;
  }
}

.Signature {
  margin-top: 5rem;
  overflow: hidden;
}

.Small {
  font-size: 0.75rem;
}
