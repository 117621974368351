.OptionContainer {
  display: flex;
  flex-direction: column;  

  button {
    border-radius: 0;
    flex-grow: 1;
  }

  button:first-child {
    border-radius: 4px 0 0 4px;
  }

  button:last-child {
    border-radius: 0 4px 4px 0;
  }
}

.AddButton {
  margin-top: 0.25rem;
}
