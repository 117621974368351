@import "../../../assets/scss/common";

.SizeLarge {
  font-size: 3rem;
}

.SizeMedium {
  font-size: 2rem;
}

.SizeSmall {
  font-size: 1rem;
}

.ColorContent {
  color: $content;
}

.ColorPrimary {
  color: $primary;
}

.Center {
  text-align: center;
}

.Middle {
  display: flex;
  width: 100vw;
  height: 100vh;
  justify-content: center;
  align-items: center;
  transition: opacity 200ms ease-out;
  opacity: 1;
  position: absolute;
  z-index: 99999;
  background-color: #FFF;
}
