@import "../../../../assets/scss/common";

.Container {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  justify-content: space-between;
  align-items: flex-start;

  .TextContainer {
    width: 100%;

    > div {
      margin-bottom: 0;
    }

    .Button {
      align-self: flex-end;
    }
  }
}
