@import "../../../assets/scss/common";

.Logo {
  box-sizing: border-box;
  width: 5rem;
  height: 5rem;
}

.Logo img {
  height: 100%;
  width: 100%;
  max-height: 100%;
  max-width: 100%;
}

.LogoContainer {
  display: flex;
  justify-content: space-between;
  margin-bottom: 4rem;
}
