@import '../../../assets/scss/common';

.Container {
  background: $content;
  // padding: 1rem;
  margin: 1rem;
  border-radius: 2px;
}

@media (min-width: 1240px) {
  .Container {
    padding: 1rem 20rem;
  }
}

@media (min-width: $mobile-width) and (max-width: 1023px) {
  .Container {
    padding: 1rem 5rem;
  }
}

@media (min-width: 1024px) and (max-width: 1239px) {
  .Container {
    padding: 1rem 15rem;
  }
}