@import '../../../assets/scss/common';

.Dropzone {
  border: 1px solid $gray-4;
  border-radius: $border-radius;
}

.Container {
  position: relative;
  padding: 0.375rem 0.75rem;
  display: flex;
  align-items: center;
}

.Icon {
  margin-right: 0.5rem;
}

.Text {
  font-size: $font-size-sm;
  color: $gray-3;
  margin-bottom: 0;
}

.PreviewContainer {
  display: flex;
  flex-direction: column;
  word-break: break-all;
}
