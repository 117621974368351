@import "../../../../assets/scss/common";

.Container {
  background: $content;
  padding: 1rem;
  border-radius: 2px;
  word-break: break-all;
}

@media (min-width: 1240px) {
  .Container {
    padding: 1rem 20rem;
  }
}

@media (min-width: $mobile-width) and (max-width: 1023px) {
  .Container {
    padding: 1rem 5rem;
  }
}

@media (min-width: 1024px) and (max-width: 1239px) {
  .Container {
    padding: 1rem 15rem;
  }
}

.Content {
  padding: 1rem;
  h1 {
    font-size: 3rem;
  }
  h2 {
    font-size: 2rem;
  }
  h3 {
    font-size: 1.75rem;
  }
  p {
    font-size: 1.5rem;
  }
}

.MarginTop {
  margin-top: 3rem;
}
