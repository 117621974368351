@import "../../../../assets/scss/common";

.Container {
  background: $content;
  padding: 1rem;
  // margin: 1rem;
  border-radius: 2px;
}

@media (min-width: 1240px) {
  .Container {
    padding: 1rem 20rem;
  }
}

@media (min-width: $mobile-width) and (max-width: 1023px) {
  .Container {
    padding: 1rem 5rem;
  }
}

@media (min-width: 1024px) and (max-width: 1239px) {
  .Container {
    padding: 1rem 15rem;
  }
}

.Title {
  font-family: Georgia, "Times New Roman", Times, serif;
  color: $secondary;
  text-transform: uppercase;
  font-weight: normal;
  //margin-bottom: 4rem;
}

.Content {
  padding: 1rem;
}

.SectionTitle {
  color: $secondary;
  font-weight: bold;
  font-style: italic;
}

.Section {
  border: 1px solid $gray-1;
  border-collapse: collapse;
  margin: 0 1rem;

  & > div:not(:last-child) {
    border-bottom: 1px solid $gray-1;
  }

  .ValueContainer {
    display: flex;
    flex-basis: 0;
    color: $text-color-2;
    white-space: pre-line;

    & > div {
      flex: 1 1 auto;
      padding: 0 1rem;
    }

    .Label {
      width: 10rem;
      text-align: center;
      border-right: 1px solid $gray-1;
    }

    .Value {
      width: 100%;
    }
  }
}
