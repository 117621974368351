@import "../../../../../assets/scss/common";

.Variation {
  th {
    text-align: left;
    min-width: 140px;
  }
  td {
    padding-left: 3rem;
    white-space: nowrap;
  }
}

@media (max-width: $mobile-width) {
  .Container {
    overflow: scroll;
  }
}
