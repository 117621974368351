@import "../../assets/scss/common";

.Container {
  padding: 1rem;
}

.TableContainer {
  overflow: scroll;
  border: 1px solid #ccc;
  padding: 0.5rem;
  /* Hide scrollbar for IE, Edge and Firefox */
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */
}

/* Hide scrollbar for Chrome, Safari and Opera */
.TableContainer::-webkit-scrollbar {
  display: none;
}

@media (min-width: $laptop-width) {
  .TableContainer {
    width: fit-content;
  }
}
