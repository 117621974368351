@import "../../../components/UI/Input/Input.module.scss";

.Container {
  padding: 1rem;
  border-radius: 2px;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  width: 100%;
}

.TableContainer {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  width: 100%;
}

.Table {
  th {
    text-align: left;
  }
}

.Report {
  cursor: pointer;
}

.Inputs {
  display: flex;
  gap: 1rem;
  align-items: center;
}

.Input {
  @include Input;
}

@media (max-width: 1023px) {
  .Scroll {
    overflow: scroll;
  }
}

@page {
  size: A4;
  margin: 2rem 1rem;
}

@media print {
  .Table {
    margin: 1rem 0;
  }
  .Input {
    display: none;
  }
  .Scroll {
    overflow: visible;
  }
  .Checkbox {
    display: none;
  }
  .Inputs {
    display: none;
  }
}
