@import "../../../../assets/scss/common";
@import "../../../UI/Input/Input.module.scss";

.LinkContainer {
  display: flex;
  flex: 1 1 0;

  .Link {
    margin-bottom: 1rem;
    position: relative;
    width: 100%;

    span {
      position: absolute;
      right: 0.75rem;
      top: 0.5rem;
      color: $gray-3;
      cursor: pointer;
    }

    input {
      padding-right: 2rem;
      background: $gray-6 !important;
    }
  }

  .PipedriveLogo {
    cursor: pointer;

    img {
      max-width: 100%;
      max-height: 2.5rem;
    }
  }
}

.Input {
  @include Input;
}
