@import '../../../assets/scss/common';

.Container {
  margin: 1rem 0;
  border-color: $primary;
  border-width: 1px;
  border-style: solid;
  position: relative;
  background: lighten($color: $tertiary, $amount: 40%);
}

.Legend {
  color: $primary;
  font-weight: bold;
}

.RemoveButton {
  @include remove-icon;
  top: -.25rem;
  right: -.5rem;
}

.Toggle {
  cursor: pointer;
  position: absolute;
  top: 0;
  right: 2rem;
}
