@import "../../../../../assets/scss/common";

.Container {
  padding: 0.25rem 0;
  width: 100%;
  & > div {
    width: 100%;
  }
  .ExtraFee {
    display: flex;
    flex-direction: row;
    @include for-phone-only {
      flex-direction: column;
    }
  }
}
