@import "../../../../assets/scss/common";

.Container {
  display: flex;
  align-items: center;
  // height: 2.5rem;
  padding: 0.25rem;
}

// .Container:not(:last-child) {
//   margin-right: 1rem;
// }

// .Label {
//   margin-left: 0.5rem;
// }

.CheckboxContainer {
  display: block;
  position: relative;
  cursor: pointer;
  font-size: 1rem;
  width: 2rem;
  height: 2rem;
  background: $content;
}

.Input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
}

.FakeInput {
  position: absolute;
  top: 0px;
  left: 0px;
  height: 2rem;
  width: 2rem;
  background-color: transparent;
  border-radius: 5px;
  transition: all 0.3s ease-out;
  border: 2px solid $dark-gray;
}

.IsActive {
  background-color: $primary;
  border-radius: 5px;
  transform: rotate(0deg) scale(1);
  opacity: 1;
  border: 2px solid darken($color: $primary, $amount: 5%);

  &:after {
    position: absolute;
    content: "";
    transform: rotate(45deg) scale(1);
    opacity: 1;
    left: 10px;
    top: 5px;
    width: 6px;
    height: 12px;
    border: solid #fff;
    border-width: 0 2px 2px 0;
    background-color: transparent;
    border-radius: 0;
  }
}

.FakeInput.Disabled {
  background: $input-disabled-bg;
}

.Disabled.IsActive {
  background-color: lighten($primary, 10%);
  border-color: lighten($color: $primary, $amount: 5%);
}

.Invalid {
  .FakeInput {
    border-color: $danger;
  }

  .Label {
    color: $danger;
  }
}

.LabelContainer {
  width: 100%;
  display: block;
  cursor: pointer;
  font-size: 1rem;
  margin-left: 0.5rem;

  input {
    // margin-left: 0.5rem;
    height: auto;
  }

  label {
    cursor: pointer;
  }
}

@media print {
  .CheckboxContainer {
    width: 3rem;
    height: 3rem;
    .FakeInput {
      width: 3rem;
      height: 3rem;
    }
    .FakeInput::after {
      left: 15px;
      top: 7.5px;
      width: 9px;
      height: 18px;
    }
  }
  .LabelContainer {
    font-size: 1.75rem;
  }
}
