@import "../../../../../assets/scss/common";

.Logo {
  box-sizing: border-box;
}

.Logo img {
  // height: 100%;
  // width: 100%;
  max-height: 75%;
  max-width: 75%;
}

.LogoContainer {
  display: flex;
  justify-content: space-between;
  //margin-bottom: 4rem;
  word-break: normal;
  gap: 1rem;
}

.Background {
  background: $secondary;
  border: 1rem solid $secondary;
}

@media (max-width: $mobile-width) {
  .LogoContainer {
    flex-direction: column;
    gap: 0;
  }
}

@media print {
  .Background {
    print-color-adjust: exact;
    -webkit-print-color-adjust: exact;
  }
}
