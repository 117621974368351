@import "../../../assets/scss/common";

.Table {
  width: 100%;
  border-collapse: collapse;
  border-spacing: 0;

  th {
    background: $gray-6;
  }

  th,
  td {
    padding: 0.25rem;
    border: 1px solid $gray-4;
  }

  .DealName {
    text-align: right;
  }
}

@media (min-width: $mobile-width) {
  .Table {
    width: fit-content;
    th,
    td {
      padding: 1rem;
    }
  }
}
