@import "../../../assets/scss/common";

.Container {
  display: flex;
  flex-direction: column;
  width: fit-content;
}

.Attachment {
  align-self: flex-end;
  margin: 0;
}

.TableContainer {
  overflow-y: scroll;
  /* Hide scrollbar for IE, Edge and Firefox */
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

/* Hide scrollbar for Chrome, Safari and Opera */
.TableContainer::-webkit-scrollbar {
  display: none;
}

.TableInfoBottom {
  text-align: right;
  margin-top: 8px;
}

.TableInfoTop {
  text-align: left;
  color: #666;
  margin-bottom: 8px;
}

.InvoiceTitle {
  margin-top: 1.5em;
  margin-bottom: 0.3em;
}

.CompactTable {
  th,
  td {
    padding: 0 0.5rem;
  }
}

.Buttons {
  flex-direction: column;
  gap: 1rem;
  justify-content: flex-end;
}

.Content {
  display: flex;
  gap: 1rem;
  flex-direction: column;
}

@media (min-width: $mobile-width) {
  .Content {
    flex-direction: row;
  }

  .Table {
    width: 70vw;
  }
}

@media (min-width: 1024px) {
  .Table {
    width: 60vw;
  }
}

@media (min-width: 1280px) {
  .Table {
    width: 40vw;
  }
}
