$gray-1: #333333;
$gray-3: #828282;
$gray-4: #bdbdbd;
$gray-5: #e0e0e0;
$gray-6: #f2f2f2;

$white: #ffffff;
$white-2: #f8f9f8;

$primary: #000000;
$secondary: #0b5058;
// #0f4f57;
$tertiary: #4398d8;

$header-1: #b5ab9b;
$header-2: #b5ab78;

$header: $secondary;
$header-link-active: $header-2;
$header-link: $header-1;

$text-color: #131313;
$text-color-2: #595959;
$border-color: #dfdfe1;
$border-radius: 3px;

$font-size-xs: 0.7rem;
$font-size-sm: 0.875rem;
$font-size-md: 0.925rem;
$font-size-label: 0.8125rem;

$mobile-width: 768px;
$laptop-width: 1024px;
$desktop-width: 1199px;

$success: #8fbb30;
$danger: #bb4c30;

$content: #fff;

$background: #f3f3f3;

$dark-gray: #b7b7b7;
$light-gray: #ddd;

$table-hover: #f5f5f5;

$input-disabled-bg: #e9ecef;
$input-border: 1px solid $dark-gray;
$input-vertical-padding: 0.375rem;
$input-horizontal-padding: 0.25rem;
$input-line-height: 1.5;
$input-border-radius: 4px;

@mixin heading {
  color: $primary;
  text-transform: uppercase;
  margin: 0 0 1rem 0;
}

@mixin remove-icon {
  position: absolute;
  top: -0.25rem;
  right: -0.5rem;
  color: #fff;
  background: $primary;
  width: 1.5rem;
  height: 1.5rem;
  border-radius: 2rem;
  display: flex;
  justify-content: center;
  align-items: center;
}

@mixin for-phone-only {
  @media (max-width: 599px) {
    @content;
  }
}

@mixin for-tablet-portrait-up {
  @media (min-width: 600px) {
    @content;
  }
}

@mixin for-tablet-landscape-up {
  @media (min-width: 900px) {
    @content;
  }
}

@mixin for-desktop-up {
  @media (min-width: 1000px) {
    @content;
  }
}

@mixin for-desktop-down {
  @media (max-width: 999px) {
    @content;
  }
}

@mixin for-big-desktop-up {
  @media (min-width: 1800px) {
    @content;
  }
}
