@import "../../../assets/scss/common";

.Container {
  text-align: center;
  width: 100%;
  margin: 0;
}

.Mobile {
  display: none;
  //@media (min-width: $mobile-width) and (max-width: $desktop-width) {
  //  display: unset;
  //}
}

.Icon {
  text-align: left;
  color: $header-link-active;
}

.Open {
  color: $text-color;
  font-weight: normal;

  & > div {
    width: 100%;
    text-align: left;
  }
}
