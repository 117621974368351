@import "../../../../../assets/scss/common";

.Note {
  width: 100%;
  display: flex;
  gap: 1rem;
  justify-content: space-between;
  border-top: 1px solid $light-gray;
  padding: 1rem;

  div {
    > p {
      margin: 0;
    }

    .Text {
      margin-top: 1rem;
      white-space: pre-line;
    }
  }

  .ButtonContainer {
    align-self: flex-end;
  }
}
