.Info {
  display: flex;
  flex-direction: row;
  align-items: center;
  text-align: center;
  padding: 1rem 0;
  gap: 1rem;

  .Item {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 0.25rem;
  }
}

@media print {
  .Info {
    justify-content: space-between;
  }
  .PrintHidden {
    display: none;
  }
}
