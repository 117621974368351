@import "../../../assets/scss/common";

.Button {
  position: relative;
  background: $primary;
  color: #fff;
  border: none;
  padding: 0.75rem 1.5rem;
  cursor: pointer;
  overflow: hidden;
  outline: none;
  border-radius: 4px;
}

.Disabled {
  background: lighten($primary, 10%);
  cursor: not-allowed;
}

.Small {
  padding: 0.5rem 1rem;
}

.Large {
  padding: 1rem 1.75rem;
}

.Secondary {
  background: $secondary;
}

.Disabled.Secondary {
  background: lighten($secondary, 10%);
}

.Danger {
  background: $danger;
}

.Disabled.Danger {
  background: lighten($danger, 10%);
}

.Success {
  background: $success;
}

.Disabled.Success {
  background: lighten($success, 10%);
}

.None {
  color: $gray-1;
  background: none;
}
